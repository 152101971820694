import {heights, mountainMod} from './constants';

const heightAdjustment = heights.plainsMin + ((heights.plainsMax - heights.plainsMin) / 4);

export function getHeight(x : number, y : number) {
    let height =
        0.5 * noise.simplex2(x / 8,   y / 8) +
        2   * noise.simplex2(x / 32,  y / 32) +
        10  * noise.simplex2(x / 100, y / 100) +
        32  * noise.simplex2(x / 200, y / 200) +
        32  * noise.simplex2(x / 500, y / 500) +
        24  * noise.simplex2(x / 900, y / 900);

    height += 30;
        
    let plainsHeight = heights.plainsMin + ((height - heights.plainsMin) / 4);

    if (height > heights.plainsMax) {
        return Math.pow(plainsHeight - heightAdjustment, heights.mountainFactor) + heightAdjustment;
    }
    return plainsHeight;
}

export function getLandTexture(x : number, y : number, height : number) {

    if (height < heights.water - 1) {
        return 0 + Math.max(height - (heights.water - 2), 0);
        // return 0;
    }
    if (height < heights.sand + 0.5){
        return 1;
    }
    if (height < heights.sand + 1.0)
        return 1 + 2 * (height - (heights.sand + 0.5));
    let snowMod = 3 + (3 * noise.simplex2(x / 20, y / 20));
    if (height > heights.plainsMax + 5 + snowMod)
        return 4;
    if (height > heights.plainsMax + 3.0 + snowMod)
        return 3 + ((height - (heights.plainsMax + 3.0 + snowMod)) * 0.5);
    let gravelMod = 5 + (5 * noise.simplex2(x / 15, y / 15));
    if (height > mountainMod + gravelMod)
        return 3;
    if (height > mountainMod + gravelMod - 2) {
        return 2 + ((height - (mountainMod + gravelMod - 2)) * 0.5);
    }
    return 2;
}

export function getTreeGeometery() : THREE.BufferGeometry {
    const greenColor = new THREE.Color(0x317830);
    const brownColor = new THREE.Color(0x6e3626);
    const geo = new THREE.Geometry();
    const level1 = new THREE.ConeGeometry(0.8,1.6,10);
    level1.faces.forEach(f => f.color.set(greenColor));
    level1.translate(0,3.7,0);
    geo.merge(level1);
    const level2 = new THREE.ConeGeometry(1.0,1.8,10);
    level2.faces.forEach(f => f.color.set(greenColor));
    level2.translate(0,2.9,0);
    geo.merge(level2);
    const level3 = new THREE.ConeGeometry(1.2,2,10);
    level3.faces.forEach(f => f.color.set(greenColor));
    level3.translate(0,2,0);
    geo.merge(level3);
    const trunk = new THREE.CylinderGeometry(0.3,0.3,2);
    trunk.translate(0, -0.0, 0);
    trunk.faces.forEach(f => f.color.set(brownColor));
    geo.merge(trunk);
    let bufferGeo = new THREE.BufferGeometry();
    bufferGeo.fromGeometry(geo);
    return bufferGeo;
}

// export function isWater(height : number) : boolean {
//     return height < heights.water + 0.55;
// }