import {getGameModel} from "./ts/game/gamemodel";
import { startApplication } from ".";

angular.module('delivery', [])
  .controller('deliveryController', ['$interval', function($interval) {
    var sd = this;

    sd.stats = {
        time : "",
        update : function() {
            sd.stats.time = (sd.gameModel.time < 10 ? "0" : "") + Math.floor(sd.gameModel.time) + ":" + (Math.floor(60 * (sd.gameModel.time % 1)) < 10 ? "0" : "") + Math.floor(60 * (sd.gameModel.time % 1));
        }
    }
    
    sd.gameModel = getGameModel();
    sd.menus = {
        settings : false
    }
    sd.hideMenus = function() {
        this.menus.settings = false;
    }
    sd.showMenu = function(name : string) {
        this.hideMenus();
        switch (name) {
            case "settings":
                this.menus.settings = true;
                break;
        }
    }


    startApplication();

    $interval(function(){
        sd.stats.update();
    }, 100);
    
  }]);