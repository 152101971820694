import * as Constants from '../terrain/constants';

class GameModel {
    public money : number = 0;
    public time : number;

    public addMoney(value : number) {
        this.money += value;
    }

    public settings = {
        terrainSettings : {
            settingsChanged : false,
            chunkSize : Constants.chunkSize.x,
            chunkRange : Constants.chunkRange
        },
        detailSettings : {
            settingsChanged : false,
            treeNumber : Constants.maximumTrees,
            grassNumber : Constants.maximumGrass,
            grassDensity : 1
        },
        rendererSettings : {
            settingsChanged : false,
            antialias : false,
            resolution : 1.0
        },
        seedSettings : {
            seed : Constants.noiseSeed,
            settingsChanged : false
        }
    }

    public options = {
        terrainOptions : {
            chunkSizeOptions : [32,64,128,192,256],
            chunkRangeOptions : [1,2,3,4,5,6,7]
        },
        detailOptions : {
            treeNumOptions : [50, 100, 200, 500, 1000, 2000],
            grassNumOptions : [500, 1000, 2000, 5000, 10000, 20000, 30000, 50000, 100000],
            grassDensityOptions : [{name:"low", value:1},{name:"medium", value: 0.5}, {name:"high", value:0.25}, {name: "very high", value : 0.2}]
        },
        rendererOptions : {
            antialiasOptions : [{name:"On",value:true} , {name:"Off", value:false}],
            resolutionOptions : [{name:"200%",value:2.0},{name:"150%",value:1.5},{name:"100%",value:1.0} , {name:"75%", value:0.75}, {name:"50%", value:0.5}],
        }
    }
}

const model = new GameModel();

export function getGameModel() : GameModel {
    return model;
}