// three.js
import * as THREE from 'three';
import {MyShaders} from "../shaders";
import MyWorker from './terrain.worker';
import {heights, noiseSeed, maximumGrass, maximumTrees} from './constants';
import {getTreeGeometery} from './utils';
import { getGameModel } from '../game/gamemodel';

const gameModel = getGameModel();
noise.seed(gameModel.settings.seedSettings.seed);
const grassTexture = new THREE.TextureLoader().load('img/grass.png');
const grassBladeTexture = new THREE.TextureLoader().load('img/grassblade.png');
const grassBladeTexture2 = new THREE.TextureLoader().load('img/grassblade2.png');
const grassBladeTexture3 = new THREE.TextureLoader().load('img/grassblade3.png');
const gravelTexture = new THREE.TextureLoader().load('img/gravel.png');
const sandTexture = new THREE.TextureLoader().load('img/sand.png');
const waterTexture = new THREE.TextureLoader().load('img/water.jpg');
const snowTexture = new THREE.TextureLoader().load('img/snow.png');
waterTexture.wrapS = THREE.MirroredRepeatWrapping;
waterTexture.wrapT = THREE.MirroredRepeatWrapping;
waterTexture.wrapT = THREE.RepeatWrapping;
gravelTexture.wrapS = snowTexture.wrapS = sandTexture.wrapS = grassTexture.wrapS = THREE.RepeatWrapping;
gravelTexture.wrapT = snowTexture.wrapT = sandTexture.wrapT = grassTexture.wrapT = THREE.RepeatWrapping;
gravelTexture.magFilter = snowTexture.magFilter = sandTexture.magFilter = grassTexture.magFilter = THREE.NearestFilter;

const uniforms = THREE.UniformsUtils.merge([THREE.ShaderLib.phong.uniforms]);
uniforms.texture1 = { type: "t", value: waterTexture };
uniforms.time = { type: "f", value: 1.0 };
uniforms.amp = { type: "f", value: 0.004 };

const waterMaterial = new THREE.ShaderMaterial({
    uniforms: uniforms,
    vertexShader: MyShaders.WaterVertexShader,
    fragmentShader: MyShaders.WaterFragmentShader,
    lights: true, transparent : true, alphaTest : 0.5,
});

// const waterMaterial = new THREE.MeshPhongMaterial({map:waterTexture, bumpMap:waterTexture, bumpScale:0.1, transparent:true, alphaTest:0.5});

const landUniforms = THREE.UniformsUtils.merge([THREE.ShaderLib.standard.uniforms]);
landUniforms.grassTexture = { type: "t", value: grassTexture };
landUniforms.sandTexture = { type: "t", value: sandTexture };
landUniforms.snowTexture = { type: "t", value: snowTexture };
landUniforms.gravelTexture = { type: "t", value: gravelTexture };

const grassUniforms = THREE.UniformsUtils.merge([THREE.ShaderLib.lambert.uniforms]);
grassUniforms.texture1 = { type: "t", value: grassBladeTexture };
grassUniforms.texture2 = { type: "t", value: grassBladeTexture2 };
grassUniforms.texture3 = { type: "t", value: grassBladeTexture3 };
grassUniforms.time = { type: "f", value: 0 };
grassUniforms.amp = { type: "f", value: 0.5 };
grassUniforms.maxDistance = { type : "f", value : 30};

const treeUniforms = THREE.UniformsUtils.merge([THREE.ShaderLib.lambert.uniforms]);
treeUniforms.time = { type: "f", value: 0 };
treeUniforms.amp = { type: "f", value: 0.05 };
treeUniforms.maxDistance = { type : "f", value : 30};

function getGrassMesh() : THREE.InstancedMesh {
    let geo = new THREE.PlaneBufferGeometry(0.5, 0.5);
    geo.setAttribute("normal", new THREE.BufferAttribute(new Float32Array([0,1,0.7,0,1,0.7, // top
                                                                            0,0.7,1,0,0.7,1]), // bottom
                                                                            3, false));
    
    return new THREE.InstancedMesh( geo, 
        new THREE.ShaderMaterial({ 
            uniforms : grassUniforms, 
            side : THREE.DoubleSide, 
            vertexShader : MyShaders.GrassVertexShader,
            fragmentShader : MyShaders.GrassFragmentShader,
            transparent : true, alphaTest : 0.5, 
            lights : true
        }), 
        // new THREE.MeshBasicMaterial(),
        gameModel.settings.detailSettings.grassNumber);
}

function getTreeMesh() : THREE.InstancedMesh {
    return new THREE.InstancedMesh( getTreeGeometery(), 
        new THREE.ShaderMaterial({
            uniforms : treeUniforms,
            vertexColors: true, 
            vertexShader : MyShaders.TreeVertexShader,
            fragmentShader : MyShaders.TreeFragmentShader,
            lights : true, transparent : true, alphaTest : 0.5,
        }),
        gameModel.settings.detailSettings.treeNumber);
}

let landMaterial = new THREE.ShaderMaterial({
    uniforms: landUniforms,
    vertexShader: MyShaders.LandVertexShader,
    // vertexShader : THREE.ShaderLib.lambert.vertexShader,
    fragmentShader: MyShaders.LandFragmentShader,
    // fragmentShader : THREE.ShaderLib.lambert.fragmentShader,
    lights: true, side : THREE.BackSide
    // fog: true
});

// new THREE.MeshLambertMaterial({side : THREE.BackSide});

// var waterGeometry = new THREE.PlaneBufferGeometry(320, 320, 480, 1);
var waterGeometry = new THREE.PlaneBufferGeometry(4000, 4000, 1, 1);
var waterPlane = new THREE.Mesh(waterGeometry, waterMaterial);
waterPlane.position.y = heights.water + 0.55;
waterPlane.rotation.x = -0.5 * Math.PI;
var staticWaterGeo = new THREE.PlaneBufferGeometry(6400, 6400, 1, 1);
var staticWater = new THREE.Mesh(staticWaterGeo, new THREE.MeshPhongMaterial({ map: waterTexture }));
staticWater.position.y = heights.water + 0.5;
staticWater.rotation.x = -0.5 * Math.PI;

export function isWater(height : number) : boolean {
    return height < waterPlane.position.y;
}

class WorkerPool {
    private workers : Worker[] = [];
    private counter : number = 0;
    private maxWorkers : number = 1;
    private onMessage : (event : MessageEvent) => void;
    constructor(maxWorkers : number, onMessage :  (event : MessageEvent) => void) {
        this.maxWorkers = maxWorkers;
        this.onMessage = onMessage;
    }
    public getWorker() : Worker {
        if (this.workers.length < this.maxWorkers) {
            let worker = new MyWorker();
            worker.onmessage = this.onMessage;
            this.workers.push(worker);
        }
        this.counter++;
        if (this.counter > this.workers.length - 1) {
            this.counter = 0;
        }
        return this.workers[this.counter];        
    }
}

class Chunk {
    public x : number;
    public y : number;
    public geo: THREE.PlaneBufferGeometry;
    public planeMesh : THREE.Mesh;
    public discarded : boolean;
    public processed : boolean;
    constructor(x : number, y : number, size : number) {
        this.x = x;
        this.y = y;
        this.geo = new THREE.PlaneBufferGeometry(size, size, size, size);
        this.planeMesh = new THREE.Mesh(this.geo, landMaterial);
        this.discarded = false;
    }
}

export class Terrain {

    public chunkRange : number = 1;
    public chunkSize : number = 32;
    private chunks : Chunk[] = [];
    private chunkMap : Chunk[][] = [];
    private discardedChunks : Chunk[] = [];
    private group : THREE.Group;
    private waterGroup : THREE.Group;
    private terrainGroup : THREE.Group;
    private workerPool : WorkerPool;
    private grassMesh : THREE.InstancedMesh;
    private treeMesh : THREE.InstancedMesh;
    private maxTreeDistance : number = 0;
    private maxGrassDistance : number = 0;
    private maxWorkers = 3;

    constructor(scene : THREE.Scene) {
        this.chunkRange = gameModel.settings.terrainSettings.chunkRange;
        this.chunkSize = gameModel.settings.terrainSettings.chunkSize;
        this.group = new THREE.Group();
        scene.add(this.group);
        this.waterGroup = new THREE.Group();
        // this.waterGroup.add(staticWater);
        this.waterGroup.add(waterPlane);
        this.group.add(this.waterGroup);
        this.terrainGroup = new THREE.Group();
        this.group.add(this.terrainGroup);
        this.grassMesh = getGrassMesh();
        this.terrainGroup.add(this.grassMesh);
        this.treeMesh = getTreeMesh();
        this.terrainGroup.add(this.treeMesh);
        let that = this;
        this.workerPool = new WorkerPool(this.maxWorkers, function(event : MessageEvent) {
            switch(event.data.type) {
                case "grass":
                    that.maxGrassDistance = event.data.maxDistance;
                    that.processGrass(event.data.grassMatrices, event.data.grassTextures);
                    break;
                case "tree":
                    that.maxTreeDistance = event.data.maxDistance;
                    that.processTrees(event.data.treeMatrices);
                    break;
                case "land":
                    // that.processChunk(event.data.x, event.data.y, event.data.pa, event.data.landTextures, event.data.normals, event.data.size);
                    that.processChunk(event.data.x, event.data.y, event.data.pa, event.data.landTextures, event.data.size);
                    break;
            }
        });
    }

    private createChunkObject (x : number, y : number) {
        if (this.discardedChunks.length > 0) {
            let chunk = this.discardedChunks.pop();
            chunk.x = x;
            chunk.y = y;
            chunk.discarded = false;
            chunk.processed = false;
            return chunk;
        }
        let chunk = new Chunk(x , y, this.chunkSize);
        this.terrainGroup.add(chunk.planeMesh);
        chunk.processed = false;
        return chunk;
    }

    private fixChunkNormals (chunk : Chunk) {
        chunk.geo.computeBoundingSphere();
        chunk.geo.computeVertexNormals();

        let hVerts = this.chunkSize + 1;
        let wVerts = this.chunkSize + 1;
        let normals = chunk.geo.getAttribute("normal");

        let foreignChunk : Chunk
        let foreignNormals : THREE.BufferAttribute | THREE.InterleavedBufferAttribute;
        let foreignIndex : number;
        if (this.chunkMap[chunk.x - this.chunkSize] && this.chunkMap[chunk.x - this.chunkSize][chunk.y]) {  // left side exists j = 0, i changes
            foreignChunk = this.chunkMap[chunk.x - this.chunkSize][chunk.y];
            if (foreignChunk.processed) {
                foreignNormals = foreignChunk.geo.getAttribute("normal");
                for (let i = 0; i < wVerts; i++) {
                    foreignIndex = (hVerts - 1) * wVerts + i;
                    normals.setXYZ(i, foreignNormals.getX(foreignIndex), foreignNormals.getY(foreignIndex), foreignNormals.getZ(foreignIndex));
                }
            }
        }

        if (this.chunkMap[chunk.x + this.chunkSize] && this.chunkMap[chunk.x + this.chunkSize][chunk.y]) {  // right side exists j = wVerts - 1, i changes
            foreignChunk = this.chunkMap[chunk.x + this.chunkSize][chunk.y];
            if (foreignChunk.processed) {
                foreignNormals = foreignChunk.geo.getAttribute("normal");
                for (let i = 0; i < wVerts; i++) {
                    foreignIndex = i;
                    normals.setXYZ((hVerts - 1) * wVerts + i, foreignNormals.getX(foreignIndex), foreignNormals.getY(foreignIndex), foreignNormals.getZ(foreignIndex));
                }
            }
        }

        if (this.chunkMap[chunk.x] && this.chunkMap[chunk.x][chunk.y + this.chunkSize]) {  // top side exists i = 0, j changes
            foreignChunk = this.chunkMap[chunk.x][chunk.y + this.chunkSize];
            if (foreignChunk.processed) {
                foreignNormals = foreignChunk.geo.getAttribute("normal");
                for (let i = 0; i < hVerts; i++) {
                    foreignIndex = i * wVerts;
                    normals.setXYZ(i * wVerts + (hVerts - 1), foreignNormals.getX(foreignIndex), foreignNormals.getY(foreignIndex), foreignNormals.getZ(foreignIndex));
                }
            }
        }

        if (this.chunkMap[chunk.x] && this.chunkMap[chunk.x][chunk.y - this.chunkSize]) {  // bottom side exists i = hVerts - 1, j changes
            foreignChunk = this.chunkMap[chunk.x][chunk.y - this.chunkSize];
            if (foreignChunk.processed) {
                foreignNormals = foreignChunk.geo.getAttribute("normal");
                for (let i = 0; i < hVerts; i++) {
                    foreignIndex = i * wVerts + (hVerts - 1);
                    normals.setXYZ(i * wVerts, foreignNormals.getX(foreignIndex), foreignNormals.getY(foreignIndex), foreignNormals.getZ(foreignIndex));
                }
            }
        }
        let pos = chunk.geo.getAttribute("position") as THREE.BufferAttribute;
        pos.needsUpdate = true;
    }

    private processGrass(grassMatrices : [], grassTextures : []) {
        let grassTextureArray = new Float32Array(grassTextures);
        if (this.grassMesh.count < grassTextureArray.length)
            return;
        this.grassMesh.instanceMatrix.copyArray(new Float32Array(grassMatrices));
        (this.grassMesh.geometry as THREE.PlaneBufferGeometry).setAttribute( 'tex', new THREE.InstancedBufferAttribute(grassTextureArray, 1, false, 1) );
        this.grassMesh.instanceMatrix.needsUpdate = true;
        this.treeMesh.instanceMatrix.needsUpdate = true;
    }

    private processTrees(treeMatrices : []) {
        let treeMatrixArray = new Float32Array(treeMatrices);
        if (this.treeMesh.count < treeMatrixArray.length / 16)
            return;
        this.treeMesh.instanceMatrix.copyArray(treeMatrixArray);
        this.treeMesh.instanceMatrix.needsUpdate = true;
    }

    private processChunk(x : number, y : number, paData : [], landTextures : [],  size : number) {
        if (size != this.chunkSize || !this.chunkMap[x] || !this.chunkMap[x][y])
            return;

        let chunk = this.chunkMap[x][y];
        chunk.planeMesh.visible = false;
        chunk.geo.setAttribute("position", new THREE.BufferAttribute(new Float32Array(paData), 3, false));
        // chunk.geo.setAttribute("normal", new THREE.BufferAttribute(new Float32Array(normals), 3, false));
        chunk.geo.setAttribute("tex", new THREE.BufferAttribute(new Float32Array(landTextures), 1, false));
        this.fixChunkNormals(chunk);
        chunk.processed = true;
        chunk.planeMesh.visible = true;
    }

    private createChunk2 (x : number, y : number) {
        let chunk = this.createChunkObject(x, y);
        this.workerPool.getWorker().postMessage(["land", x, y, this.chunkSize]);
        
        this.chunks.push(chunk);
        if (!this.chunkMap[x])
            this.chunkMap[x] = [];

        this.chunkMap[x][y] = chunk;
    }

    private destroyChunk(chunk : Chunk) {
        chunk.discarded = true;
        this.discardedChunks.push(chunk);
        delete this.chunkMap[chunk.x][chunk.y];
        if (Object.keys(this.chunkMap[chunk.x]).length == 0)
            delete this.chunkMap[chunk.x];
    }

    private destroyAllChunks() {
        for (var i = 0; i < this.chunks.length; i++) {
            let chunk = this.chunks[i];
            chunk.planeMesh.geometry.dispose();
            this.terrainGroup.remove( chunk.planeMesh );
        }
        this.chunks = [];
        this.discardedChunks = [];
        this.chunkMap = [];
    }

    private updateSettings() {
        this.chunkRange = gameModel.settings.terrainSettings.chunkRange;
        this.chunkSize = gameModel.settings.terrainSettings.chunkSize;
        gameModel.settings.terrainSettings.settingsChanged = false;
        this.lastCubeCenter = { x: 1, y: 1 };
        this.destroyAllChunks();
    }

    private updateDetailSettings() {
        this.grassMesh.geometry.dispose();
        this.terrainGroup.remove(this.grassMesh);
        this.treeMesh.geometry.dispose();
        this.terrainGroup.remove(this.treeMesh);
        this.grassMesh = getGrassMesh();
        this.terrainGroup.add(this.grassMesh);
        this.treeMesh = getTreeMesh();
        this.terrainGroup.add(this.treeMesh);
        this.lastGrassCenter.x -= 50;
        this.lastCubeCenter.x -= 2;
        gameModel.settings.detailSettings.settingsChanged = false;
    }

    private lastCubeCenter = { x: 1, y: 1 };
    private lastGrassCenter = {x : 0, y: 0};

    private updateUniforms(timeDiff : number) {
        uniforms.time.value += timeDiff * 60;
        grassUniforms.time.value += timeDiff * 60;
        if (grassUniforms.maxDistance.value > this.maxGrassDistance) {
            grassUniforms.maxDistance.value -= 10 * timeDiff;
        }
        if (grassUniforms.maxDistance.value < this.maxGrassDistance) {
            grassUniforms.maxDistance.value += 10 * timeDiff;
        }
        // console.log(this.maxGrassDistance);
        // console.log(grassUniforms.maxDistance.value);
        treeUniforms.time.value += timeDiff * 60;
        if (treeUniforms.maxDistance.value > this.maxTreeDistance) {
            treeUniforms.maxDistance.value -= 10 * timeDiff;
        }
        if (treeUniforms.maxDistance.value < this.maxTreeDistance) {
            treeUniforms.maxDistance.value += 10 * timeDiff;
        }
    }

    public updateTides(sunPos : number) {
        waterPlane.position.y = heights.water + 0.55 + (sunPos / -3000);
    }

    public updateChunks(timeDiff : number, camera : THREE.Camera) {

        if (gameModel.settings.seedSettings.settingsChanged) {
            gameModel.settings.seedSettings.settingsChanged = false;
            if (!isNaN(gameModel.settings.seedSettings.seed)) {
                for (let i = 0; i < this.maxWorkers; i++)
                    this.workerPool.getWorker().postMessage(["seed", gameModel.settings.seedSettings.seed]);
                noise.seed(gameModel.settings.seedSettings.seed);
                this.lastGrassCenter.x -= 50;
                this.lastCubeCenter.x -= 2;
                camera.position.x = 0;
                camera.position.z = 0;
                this.destroyAllChunks();
            }
        }

        if (gameModel.settings.terrainSettings.settingsChanged) {
            this.updateSettings();
        }
        if (gameModel.settings.detailSettings.settingsChanged) {
            this.updateDetailSettings();
        }
        this.updateUniforms(timeDiff);
        let offsetX = Math.round(camera.position.x);
        let offsetY = Math.round(camera.position.z);
        if (Math.abs(offsetX - this.waterGroup.position.x) > 64 || Math.abs(offsetY - this.waterGroup.position.z) > 64) {
            this.waterGroup.position.x = offsetX;
            this.waterGroup.position.z = offsetY;
        }

        if (this.lastCubeCenter.x == offsetX && this.lastCubeCenter.y == offsetY)
            return;

        if (Math.abs(offsetX - this.lastGrassCenter.x) > 3 || Math.abs(offsetY - this.lastGrassCenter.y) > 3) {
            this.workerPool.getWorker().postMessage(["grass", offsetX, offsetY, gameModel.settings.detailSettings.grassDensity,
                gameModel.settings.detailSettings.grassNumber]);
            this.workerPool.getWorker().postMessage(["tree", offsetX, offsetY, gameModel.settings.detailSettings.treeNumber]);
            this.lastGrassCenter.x = offsetX;
            this.lastGrassCenter.y = offsetY;
        }

        let centerChunk = {
            x: Math.round(offsetX / this.chunkSize) * this.chunkSize,
            y: Math.round(offsetY / this.chunkSize) * this.chunkSize,
        }
        var minX = centerChunk.x - (this.chunkRange * this.chunkSize);
        var maxX = centerChunk.x + (this.chunkRange * this.chunkSize);
        var minY = centerChunk.y - (this.chunkRange * this.chunkSize);
        let maxY = centerChunk.y + (this.chunkRange * this.chunkSize);
        for (var i = 0; i < this.chunks.length; i++) {
            if (this.chunks[i].x < minX || this.chunks[i].x > maxX || this.chunks[i].y < minY || this.chunks[i].y > maxY) {
                this.destroyChunk(this.chunks[i]);
                this.chunks.splice(i, 1);
                return;
            }
        }
        for (var i = -this.chunkRange; i <= this.chunkRange; i++) {
            var x = centerChunk.x + (i * this.chunkSize);
            for (var j = -this.chunkRange; j <= this.chunkRange; j++) {
                var y = centerChunk.y + (j * this.chunkSize);
                if (!this.chunkMap[x] || !this.chunkMap[x][y]) {
                    this.createChunk2(x, y);
                    return;
                }
            }
        }
        this.lastCubeCenter.x = offsetX;
        this.lastCubeCenter.y = offsetY;
    }
}