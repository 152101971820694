// three.js
import * as THREE from 'three';

export class MyCamera {

    public camera : THREE.PerspectiveCamera;

    constructor(scene : THREE.Scene) {
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 3000);
        // this.camera.rotation.y = Math.PI;
        this.camera.position.x = 96;
        this.camera.position.y = -100000;
        this.camera.position.z = 33;
        scene.add(this.camera);
    }
    
}