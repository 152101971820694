import { MyCamera } from "./camera";
import { Vehicle, Walking, MapMode, Boat } from "./vehicles";
import { getGameModel } from "./gamemodel";

export class Controls {

	public flashLight : boolean = true;

    public KeysPressed = {
        w: false,
        a: false,
        s: false,
        d: false,
        q: false,
        e: false,
		f: false,
		m: false
    }

	public camera : MyCamera
	public currentVehicle : Vehicle
	public lastVehicle : Vehicle
	public vehicleWalking : Walking
	public vehicleMap : MapMode
	public vehicleBoat : Boat;
	private gameModel = getGameModel();

	public handleMovement(timeDiff : number) {
		this.currentVehicle.handleMovement(timeDiff);
	}

	public toggleMap() {
		if (this.currentVehicle instanceof MapMode) {
			this.currentVehicle.onDestroy();
			this.currentVehicle = this.lastVehicle;
			this.currentVehicle.onInit();
		} else {
			this.lastVehicle = this.currentVehicle;
			this.currentVehicle.onDestroy();
			this.currentVehicle = this.vehicleMap;
			this.currentVehicle.onInit();
		}
	}

    
    constructor(myCamera : MyCamera) {
        let KeysPressed = this.KeysPressed;
		let that = this;
		this.camera = myCamera;
		this.vehicleWalking = new Walking(this.camera.camera, this);
		this.vehicleMap = new MapMode(this.camera.camera, this);
		this.vehicleBoat = new Boat(this.camera.camera, this);
		this.currentVehicle = this.vehicleWalking;

        window.onblur = function () {
			KeysPressed.w = KeysPressed.a = KeysPressed.s = KeysPressed.d = false;
		};

		window.onkeydown = function (e: { keyCode: any; }) {
			switch (e.keyCode) {
				case 87:
				case 38:
					KeysPressed.w = true;
					break;
				case 65:
				case 37:
					KeysPressed.a = true;
					break;
				case 83:
				case 40:
					KeysPressed.s = true;
					break;
				case 68:
				case 39:
					KeysPressed.d = true;
					break;
				case 81:
					KeysPressed.q = true;
					break;
				case 69:
					KeysPressed.e = true;
					break;
				case 70:
					KeysPressed.f = true;
					that.flashLight = !that.flashLight;
					break;
				case 77:
					KeysPressed.m = true;
					that.toggleMap();
					break;
				default:
					return true;
			}
			return false;
		};
		window.onkeyup = function (e: { keyCode: any; }) {
			switch (e.keyCode) {
				case 87:
				case 38:
					KeysPressed.w = false;
					break;
				case 65:
				case 37:
					KeysPressed.a = false;
					break;
				case 83:
				case 40:
					KeysPressed.s = false;
					break;
				case 68:
				case 39:
					KeysPressed.d = false;
					break;
				case 81:
					KeysPressed.q = false;
					break;
				case 69:
					KeysPressed.e = false;
					break;
				case 70:
					KeysPressed.f = false;
					break;
				case 77:
					KeysPressed.m = true;
					break;
				default:
					return true;
			}
			return false;
        };
        
        window.onwheel = function (e: { deltaY: number; }) {
			if (that.currentVehicle instanceof MapMode) {
				that.currentVehicle.preferredZoom -= e.deltaY * 0.05;
				if (that.currentVehicle.preferredZoom < 1) {
					that.currentVehicle.preferredZoom = 1;
				}
				if (that.currentVehicle.preferredZoom > 1800) {
					that.currentVehicle.preferredZoom = 1800;
				}
			} else {
				// that.camera.camera.rotation.x -= e.deltaY * 0.01;
			}
		};
    }
}