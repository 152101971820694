export const heights = {
    water: 4.3,
    sand: 5.0,
    plainsMin: 4,
    plainsMax: 35,
    mountainFactor : 1.6
}
export const mountainMod = heights.plainsMax - (heights.plainsMin + ((heights.plainsMax - heights.plainsMin) / 4));
export const chunkSize = { x: 128, y: 128 };
export const chunkRange = 3;
export const noiseSeed = 1024;
export const maximumTrees = 500;
export const maximumGrass = 2000;