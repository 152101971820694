export const MyShaders = {

  TreeVertexShader: `
          #define MYGRASS
          varying vec3 vLightFront;
          varying vec3 vIndirectFront;
          #ifdef DOUBLE_SIDED
              varying vec3 vLightBack;
              varying vec3 vIndirectBack;
          #endif
          #include <common>
          #include <uv_pars_vertex>
          #include <uv2_pars_vertex>
          #include <envmap_pars_vertex>
          #include <bsdfs>
          #include <lights_pars_begin>
          #include <color_pars_vertex>
          #include <fog_pars_vertex>
          #include <morphtarget_pars_vertex>
          #include <skinning_pars_vertex>
          #include <shadowmap_pars_vertex>
          #include <logdepthbuf_pars_vertex>
          #include <clipping_planes_pars_vertex>

          varying vec3 vUv;
          uniform float time;
          uniform float amp;
          uniform float maxDistance;
          varying float fade;

          void main() {

              vUv = position;
      
              #include <uv_vertex>
              #include <uv2_vertex>
              #include <color_vertex>
              #include <beginnormal_vertex>
              #include <morphnormal_vertex>
              #include <skinbase_vertex>
              #include <skinnormal_vertex>
              #include <defaultnormal_vertex>
              #include <begin_vertex>
              #include <morphtarget_vertex>
              #include <skinning_vertex>
              
              vec4 mvPosition = vec4( transformed, 1.0 );
              if (position.y > 1.0) {
                float freq = 0.01;
                float angle = time * freq;
                angle += instanceMatrix[3].x;
                mvPosition.z += cos(angle) * amp * position.y;
              }

              #ifdef USE_INSTANCING
                mvPosition = instanceMatrix * mvPosition;
              #endif

              float dist = distance(vec3(mvPosition.x, 0.0, mvPosition.z), vec3(cameraPosition.x, 0.0, cameraPosition.z));

              mvPosition = modelViewMatrix * mvPosition;              
              gl_Position = projectionMatrix * mvPosition;
              #include <logdepthbuf_vertex>
              #include <clipping_planes_vertex>
              #include <worldpos_vertex>
              #include <envmap_vertex>
              #include <lights_lambert_vertex>
              #include <shadowmap_vertex>
              #include <fog_vertex>

              fade = 1.0;             

              if (dist > maxDistance * 0.9) {
                if (dist > maxDistance) {
                  fade = 0.0;
                } else {
                  fade = ((maxDistance - dist) / (maxDistance * 0.1));
                  // fade = 1.0 + (mvPosition.z / maxDistance);
                }
              }

              // vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
	            // gl_Position = projectionMatrix * modelViewPosition;
          }
      `,

  TreeFragmentShader: `
      uniform vec3 diffuse;
      uniform vec3 emissive;
      uniform float opacity;
      varying vec3 vLightFront;
      varying vec3 vIndirectFront;
      #ifdef DOUBLE_SIDED
          varying vec3 vLightBack;
          varying vec3 vIndirectBack;
      #endif
      #include <common>
      #include <packing>
      #include <dithering_pars_fragment>
      #include <color_pars_fragment>
      #include <uv_pars_fragment>
      #include <uv2_pars_fragment>
      #include <map_pars_fragment>
      #include <alphamap_pars_fragment>
      #include <aomap_pars_fragment>
      #include <lightmap_pars_fragment>
      #include <emissivemap_pars_fragment>
      #include <envmap_common_pars_fragment>
      #include <envmap_pars_fragment>
      #include <cube_uv_reflection_fragment>
      #include <bsdfs>
      #include <lights_pars_begin>
      #include <fog_pars_fragment>
      #include <shadowmap_pars_fragment>
      #include <shadowmask_pars_fragment>
      #include <specularmap_pars_fragment>
      #include <logdepthbuf_pars_fragment>
      #include <clipping_planes_pars_fragment>

      varying vec3 vUv;
      varying float fade;
      
      void main() {
          #include <clipping_planes_fragment>

          vec4 diffuseColor = vec4( diffuse, opacity );
          ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
          vec3 totalEmissiveRadiance = emissive;
          #include <logdepthbuf_fragment>
          #include <map_fragment>
          #include <color_fragment>
          #include <alphamap_fragment>
          #include <alphatest_fragment>
          #include <specularmap_fragment>
          #include <emissivemap_fragment>
          reflectedLight.indirectDiffuse = getAmbientLightIrradiance( ambientLightColor );
          #ifdef DOUBLE_SIDED
              reflectedLight.indirectDiffuse += ( gl_FrontFacing ) ? vIndirectFront : vIndirectBack;
          #else
              reflectedLight.indirectDiffuse += vIndirectFront;
          #endif
          #include <lightmap_fragment>
          reflectedLight.indirectDiffuse *= BRDF_Diffuse_Lambert( diffuseColor.rgb );
          #ifdef DOUBLE_SIDED
              reflectedLight.directDiffuse = ( gl_FrontFacing ) ? vLightFront : vLightBack;
          #else
              reflectedLight.directDiffuse = vLightFront;
          #endif
          reflectedLight.directDiffuse *= BRDF_Diffuse_Lambert( diffuseColor.rgb ) * getShadowMask();
          #include <aomap_fragment>
          vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;
          #include <envmap_fragment>
          if (fade < 1.0) {
            diffuseColor.a = fade;
          }
          gl_FragColor = vec4( outgoingLight, diffuseColor.a );
          #include <tonemapping_fragment>
          #include <encodings_fragment>
          #include <fog_fragment>
          #include <premultiplied_alpha_fragment>
          #include <dithering_fragment>
      }
    `,

  GrassVertexShader: `
          #define MYGRASS
          varying vec3 vLightFront;
          varying vec3 vIndirectFront;
          #ifdef DOUBLE_SIDED
              varying vec3 vLightBack;
              varying vec3 vIndirectBack;
          #endif
          #include <common>
          #include <uv_pars_vertex>
          #include <uv2_pars_vertex>
          #include <envmap_pars_vertex>
          #include <bsdfs>
          #include <lights_pars_begin>
          #include <color_pars_vertex>
          #include <fog_pars_vertex>
          #include <morphtarget_pars_vertex>
          #include <skinning_pars_vertex>
          #include <shadowmap_pars_vertex>
          #include <logdepthbuf_pars_vertex>
          #include <clipping_planes_pars_vertex>

          varying vec3 vUv;
          attribute float tex;
          varying float texFloat;
          uniform float time;
          uniform float amp;
          uniform float maxDistance;
          varying float fade;

          void main() {

              vUv = position;
              texFloat = tex;
      
              #include <uv_vertex>
              #include <uv2_vertex>
              #include <color_vertex>
              #include <beginnormal_vertex>
              #include <morphnormal_vertex>
              #include <skinbase_vertex>
              #include <skinnormal_vertex>
              #include <defaultnormal_vertex>
              #include <begin_vertex>
              #include <morphtarget_vertex>
              #include <skinning_vertex>
              
              vec4 mvPosition = vec4( transformed, 1.0 );
              if (position.y > 0.0) {
                float freq = 0.2 * min(max(instanceMatrix[0].x, 0.7), 1.8);
                float angle = ((time * 0.2) + (position.y * 8.0)) * freq;
                angle += instanceMatrix[3].x;
                mvPosition.z += cos(angle) * amp * freq;
              }

              #ifdef USE_INSTANCING
                mvPosition = instanceMatrix * mvPosition;
              #endif

              float dist = distance(vec3(mvPosition.x, 0.0, mvPosition.z), vec3(cameraPosition.x, 0.0, cameraPosition.z));

              mvPosition = modelViewMatrix * mvPosition;              
              gl_Position = projectionMatrix * mvPosition;
              #include <logdepthbuf_vertex>
              #include <clipping_planes_vertex>
              #include <worldpos_vertex>
              #include <envmap_vertex>
              #include <lights_lambert_vertex>
              #include <shadowmap_vertex>
              #include <fog_vertex>

              fade = 1.0;             

              if (dist > maxDistance * 0.5) {
                if (dist > maxDistance) {
                  fade = 0.0;
                } else {
                  fade = ((maxDistance - dist) / (maxDistance * 0.5));
                  // fade = 1.0 + (mvPosition.z / maxDistance);
                }
              }

              // vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
	            // gl_Position = projectionMatrix * modelViewPosition;
          }
      `,

  GrassFragmentShader: `
          uniform vec3 diffuse;
          uniform vec3 emissive;
          uniform float opacity;
          varying vec3 vLightFront;
          varying vec3 vIndirectFront;
          #ifdef DOUBLE_SIDED
              varying vec3 vLightBack;
              varying vec3 vIndirectBack;
          #endif
          #include <common>
          #include <packing>
          #include <dithering_pars_fragment>
          #include <color_pars_fragment>
          #include <uv_pars_fragment>
          #include <uv2_pars_fragment>
          #include <map_pars_fragment>
          #include <alphamap_pars_fragment>
          #include <aomap_pars_fragment>
          #include <lightmap_pars_fragment>
          #include <emissivemap_pars_fragment>
          #include <envmap_common_pars_fragment>
          #include <envmap_pars_fragment>
          #include <cube_uv_reflection_fragment>
          #include <bsdfs>
          #include <lights_pars_begin>
          #include <fog_pars_fragment>
          #include <shadowmap_pars_fragment>
          #include <shadowmask_pars_fragment>
          #include <specularmap_pars_fragment>
          #include <logdepthbuf_pars_fragment>
          #include <clipping_planes_pars_fragment>
  
          uniform sampler2D texture1;
          uniform sampler2D texture2;
          uniform sampler2D texture3;
          varying vec3 vUv;
          varying float texFloat;
          varying float fade;
          
          void main() {
              #include <clipping_planes_fragment>
  
              vec4 diffuseColor = vec4(0.0,0.0,0.0,0.0);

              if (texFloat < 0.3) {
                diffuseColor = texture2D(texture1, 1.6 * vUv.xy + vec2(.51, .51));
              } else if (texFloat > 0.7) {
                diffuseColor = texture2D(texture2, 1.8 * vUv.xy + vec2(.51, .51));
              } else {
                diffuseColor = texture2D(texture3, 1.8 * vUv.xy + vec2(.53, .51));
              }
  
              ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
              vec3 totalEmissiveRadiance = emissive;
              #include <logdepthbuf_fragment>
              #include <map_fragment>
              #include <color_fragment>
              #include <alphamap_fragment>
              #include <alphatest_fragment>
              #include <specularmap_fragment>
              #include <emissivemap_fragment>
              reflectedLight.indirectDiffuse = getAmbientLightIrradiance( ambientLightColor );
              #ifdef DOUBLE_SIDED
                  reflectedLight.indirectDiffuse += ( gl_FrontFacing ) ? vIndirectFront : vIndirectBack;
              #else
                  reflectedLight.indirectDiffuse += vIndirectFront;
              #endif
              #include <lightmap_fragment>
              reflectedLight.indirectDiffuse *= BRDF_Diffuse_Lambert( diffuseColor.rgb );
              #ifdef DOUBLE_SIDED
                  reflectedLight.directDiffuse = ( gl_FrontFacing ) ? vLightFront : vLightBack;
              #else
                  reflectedLight.directDiffuse = vLightFront;
              #endif
              reflectedLight.directDiffuse *= BRDF_Diffuse_Lambert( diffuseColor.rgb ) * getShadowMask();
              #include <aomap_fragment>
              vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;
              #include <envmap_fragment>
              if (fade < 1.0) {
                diffuseColor.a = fade;
              }
              gl_FragColor = vec4( outgoingLight, diffuseColor.a );
              #include <tonemapping_fragment>
              #include <encodings_fragment>
              #include <fog_fragment>
              #include <premultiplied_alpha_fragment>
              #include <dithering_fragment>
          }
      `,

  LandVertexShader: `
          #define MYLAND
          varying vec3 vLightFront;
          varying vec3 vIndirectFront;
          #ifdef DOUBLE_SIDED
              varying vec3 vLightBack;
              varying vec3 vIndirectBack;
          #endif
          #include <common>
          #include <uv_pars_vertex>
          #include <uv2_pars_vertex>
          #include <envmap_pars_vertex>
          #include <bsdfs>
          #include <lights_pars_begin>
          #include <color_pars_vertex>
          #include <fog_pars_vertex>
          #include <morphtarget_pars_vertex>
          #include <skinning_pars_vertex>
          #include <shadowmap_pars_vertex>
          #include <logdepthbuf_pars_vertex>
          #include <clipping_planes_pars_vertex>
          attribute float tex;
          varying vec3 vUv;
          varying float texFloat;
          void main() {
              #include <uv_vertex>
              #include <uv2_vertex>
              #include <color_vertex>
              #include <beginnormal_vertex>
              #include <morphnormal_vertex>
              #include <skinbase_vertex>
              #include <skinnormal_vertex>
              #include <defaultnormal_vertex>
              #include <begin_vertex>
              #include <morphtarget_vertex>
              #include <skinning_vertex>
              #include <project_vertex>
              #include <logdepthbuf_vertex>
              #include <clipping_planes_vertex>
              #include <worldpos_vertex>
              #include <envmap_vertex>
              #include <lights_lambert_vertex>
              #include <shadowmap_vertex>
              #include <fog_vertex>
  
              vUv = position;
              texFloat = tex;
          }
      `,

  LandFragmentShader: `
          uniform vec3 diffuse;
          uniform vec3 emissive;
          uniform float opacity;
          varying vec3 vLightFront;
          varying vec3 vIndirectFront;
          #ifdef DOUBLE_SIDED
              varying vec3 vLightBack;
              varying vec3 vIndirectBack;
          #endif
          #include <common>
          #include <packing>
          #include <dithering_pars_fragment>
          #include <color_pars_fragment>
          #include <uv_pars_fragment>
          #include <uv2_pars_fragment>
          #include <map_pars_fragment>
          #include <alphamap_pars_fragment>
          #include <aomap_pars_fragment>
          #include <lightmap_pars_fragment>
          #include <emissivemap_pars_fragment>
          #include <envmap_common_pars_fragment>
          #include <envmap_pars_fragment>
          #include <cube_uv_reflection_fragment>
          #include <bsdfs>
          #include <lights_pars_begin>
          #include <fog_pars_fragment>
          #include <shadowmap_pars_fragment>
          #include <shadowmask_pars_fragment>
          #include <specularmap_pars_fragment>
          #include <logdepthbuf_pars_fragment>
          #include <clipping_planes_pars_fragment>

          uniform sampler2D grassTexture;
          uniform sampler2D sandTexture;
          uniform sampler2D gravelTexture;
          uniform sampler2D snowTexture;
          varying vec3 vUv;
          varying float texFloat;
  
          void main() {
              #include <clipping_planes_fragment>

              vec4 color = vec4(0.0,0.0,0.0,0.0);
              float tScale = 1.0;

              if (texFloat > 0.0 && texFloat <= 1.0) {
                color = mix(color, texture2D(sandTexture, vUv.xz * tScale), texFloat);
              }
              if (texFloat > 1.0 && texFloat <= 2.0) {
                color = texture2D(sandTexture, vUv.xz * tScale);
                color = mix(color, texture2D(grassTexture, vUv.xz * tScale), texFloat - 1.0);
              }
              if (texFloat > 2.0 && texFloat <= 3.0) {
                color = texture2D(grassTexture, vUv.xz * tScale);
                color = mix(color, texture2D(gravelTexture, vUv.xz * tScale), texFloat - 2.0);
              }
              if (texFloat > 3.0 && texFloat <= 4.0) {
                color = texture2D(gravelTexture, vUv.xz * tScale);
                color = mix(color, texture2D(snowTexture, vUv.xz * tScale), texFloat - 3.0);
              }
  
              ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
              vec3 totalEmissiveRadiance = emissive;
              #include <logdepthbuf_fragment>
              #include <map_fragment>
              #include <color_fragment>
              #include <alphamap_fragment>
              #include <alphatest_fragment>
              #include <specularmap_fragment>
              #include <emissivemap_fragment>
              //reflectedLight.indirectDiffuse = getAmbientLightIrradiance( ambientLightColor );
              #ifdef DOUBLE_SIDED
                  reflectedLight.indirectDiffuse += ( gl_FrontFacing ) ? vIndirectFront : vIndirectBack;
              #else
                  reflectedLight.indirectDiffuse += vIndirectFront;
              #endif
              #include <lightmap_fragment>
              reflectedLight.indirectDiffuse *= BRDF_Diffuse_Lambert( color.rgb );
              #ifdef DOUBLE_SIDED
                  reflectedLight.directDiffuse = ( gl_FrontFacing ) ? vLightFront : vLightBack;
              #else
                  reflectedLight.directDiffuse = vLightFront;
              #endif
              reflectedLight.directDiffuse *= BRDF_Diffuse_Lambert( color.rgb ) * getShadowMask();
              #include <aomap_fragment>
              vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;
              #include <envmap_fragment>
              gl_FragColor = vec4( outgoingLight, color.a );
              #include <tonemapping_fragment>
              #include <encodings_fragment>
              #include <fog_fragment>
              #include <premultiplied_alpha_fragment>
              #include <dithering_fragment>
          }
      `,

  WaterVertexShader: `
          #define MYWATER
          varying vec3 vViewPosition;
          #ifndef FLAT_SHADED
              varying vec3 vNormal;
          #endif
          #include <common>
          #include <uv_pars_vertex>
          #include <uv2_pars_vertex>
          #include <displacementmap_pars_vertex>
          #include <envmap_pars_vertex>
          #include <color_pars_vertex>
          #include <fog_pars_vertex>
          #include <morphtarget_pars_vertex>
          #include <skinning_pars_vertex>
          #include <shadowmap_pars_vertex>
          #include <logdepthbuf_pars_vertex>
          #include <clipping_planes_pars_vertex>
  
          varying vec3 vUv;
          uniform float time;
          uniform float amp;
  
          void main() {
              vUv = position; 
              #include <uv_vertex>
              #include <uv2_vertex>
              #include <color_vertex>
              #include <beginnormal_vertex>
              #include <morphnormal_vertex>
              #include <skinbase_vertex>
              #include <skinnormal_vertex>
              #include <defaultnormal_vertex>
          #ifndef FLAT_SHADED
              vNormal = normalize( transformedNormal );
          #endif
              #include <begin_vertex>
              #include <morphtarget_vertex>
              #include <skinning_vertex>
              #include <displacementmap_vertex>
              #include <project_vertex>
              #include <logdepthbuf_vertex>
              #include <clipping_planes_vertex>
              vViewPosition = - mvPosition.xyz;
              #include <worldpos_vertex>
              #include <envmap_vertex>
              #include <shadowmap_vertex>
              #include <fog_vertex>
  
            vNormal = normalMatrix * normal;
            //float freq = 0.3;
            //float angle = ((time * 0.2) + (position.x * 8.0))*freq;
            //vUv.z += sin(angle)*amp;
            //vNormal = normalMatrix * normalize(vec3(-amp * freq * cos(angle),0.0,1.0));
            vec4 modelViewPosition = modelViewMatrix * vec4(vUv, 1.0);
            gl_Position = projectionMatrix * modelViewPosition;
          }
      `,

  WaterFragmentShader: `
          #define MYWATER
          uniform vec3 diffuse;
          uniform vec3 emissive;
          uniform vec3 specular;
          uniform float shininess;
          uniform float opacity;
          #include <common>
          #include <packing>
          #include <dithering_pars_fragment>
          #include <color_pars_fragment>
          #include <uv_pars_fragment>
          #include <uv2_pars_fragment>
          #include <map_pars_fragment>
          #include <alphamap_pars_fragment>
          #include <aomap_pars_fragment>
          #include <lightmap_pars_fragment>
          #include <emissivemap_pars_fragment>
          #include <envmap_common_pars_fragment>
          #include <envmap_pars_fragment>
          #include <cube_uv_reflection_fragment>
          #include <fog_pars_fragment>
          #include <bsdfs>
          #include <lights_pars_begin>
          #include <lights_phong_pars_fragment>
          #include <shadowmap_pars_fragment>
          #include <bumpmap_pars_fragment>
          #include <normalmap_pars_fragment>
          #include <specularmap_pars_fragment>
          #include <logdepthbuf_pars_fragment>
          #include <clipping_planes_pars_fragment>
  
          varying vec3 vPos;
          uniform float time;
          uniform sampler2D texture1;
              varying vec3 vUv;

          float noise(vec2 n) {
              const vec2 d = vec2(0.0, 1.0);
                vec2 b = floor(n), f = smoothstep(vec2(0.0), vec2(1.0), fract(n));
              return mix(mix(rand(b), rand(b + d.yx), f.x), mix(rand(b + d.xy), rand(b + d.yy), f.x), f.y);
          }
          
          void main() {
            #include <clipping_planes_fragment>
            // compute moving color            
  
            // Compute the ripple effect.
            float mod1 = 16.0;
            float mod2 = 0.4;
            float xoffset = 0.05 * cos(time / 52.0 + mod1 * vUv.y) + 0.09 * cos(time / 65.0 + mod2 * vUv.y);
            float time2 = time + 0.8;
            float yoffset = 0.02 * cos(time2 / 35.0 + mod1 * vUv.x) + 0.07 * cos(time2 / 55.0 + mod2 * vUv.x);
            vec4 diffuseColor = texture2D(texture1, vec2((vUv.x + xoffset) * 0.25, (vUv.y + yoffset) * 0.25));
            // vec4 diffuseColor = texture2D(texture1, vUv.xy + (time  * 0.001));
            
            //vec4 diffuseColor = vec4( diffuse, opacity );
            ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
            vec3 totalEmissiveRadiance = emissive;
            #include <logdepthbuf_fragment>
            #include <map_fragment>
            #include <color_fragment>
            #include <alphamap_fragment>
            #include <alphatest_fragment>
            #include <specularmap_fragment>
            #include <normal_fragment_begin>
            float nr = noise(vec2(vUv.x + (time * 0.01) + 2.0, vUv.y) * 3.0) - 0.5;
            float nr2 = noise(vec2(vUv.x, vUv.y + (time * 0.01)) * 3.5) - 0.5;
            float res = mix(nr, nr2, 0.5);
            // normal.z = normal.z - 0.1 + (nr * 0.1 * cos((time + (vUv.x + vUv.y) * 10.0)/ 25.0));
            if (res > 0.0) {
              normal.z = normal.z - 0.1 + res * 0.2;
            } else {
              normal.z = normal.z - 0.1;
            }

            #include <normal_fragment_maps>
            #include <emissivemap_fragment> 
            #include <lights_phong_fragment>
            #include <lights_fragment_begin>
            #include <lights_fragment_maps>
            #include <lights_fragment_end>
            #include <aomap_fragment>
            vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;
            #include <envmap_fragment>
            gl_FragColor = vec4( outgoingLight, diffuseColor.a );
            #include <tonemapping_fragment>
            #include <encodings_fragment>
            #include <fog_fragment>
            #include <premultiplied_alpha_fragment>
            #include <dithering_fragment>
            gl_FragColor.a = 0.9;
          }
      `
}