// add styles
import './style.css';
// three.js
import * as THREE from 'three';
import * as Terrain from './ts/terrain/terrain';
import * as Camera from './ts/game/camera';
import { Controls } from './ts/game/controls';
import { getGameModel } from './ts/game/gamemodel';

export function startApplication() {
  const gameModel = getGameModel();

  // create the scene
  const scene = new THREE.Scene();

  let terrain = new Terrain.Terrain(scene);

  // create the camera and controls
  const camera = new Camera.MyCamera(scene);
  const controls = new Controls(camera);

  const renderer = new THREE.WebGLRenderer({ antialias: false });
  // set size
  renderer.setSize(window.innerWidth, window.innerHeight);

  let dayColor = new THREE.Color(0x6598ba);
  let nightColor = new THREE.Color(0x001848);
  scene.background = new THREE.Color(0, 0, 0);

  // add canvas to dom
  document.body.appendChild(renderer.domElement);

  // setup lights
  let light = new THREE.SpotLight(0xffffdd, 0.6, 100, 0.8, 0.5, 1);
  camera.camera.add(light);
  light.position.set(0, 0, 3);
  light.target = camera.camera;

  // let ambLight = new THREE.AmbientLight(0x909090, 0.5);
  // scene.add(ambLight);

  let sunGroup = new THREE.Group();
  scene.add(sunGroup);

  const sunMax = 0.5;
  const sunLightColor = new THREE.Color(0xEEEEDD);
  const moonLightColor = new THREE.Color(0x303030);
  let dirLight = new THREE.DirectionalLight(sunLightColor, sunMax);
  dirLight.position.y = 2000;
  dirLight.position.z = 1000;
  dirLight.target = camera.camera;
  sunGroup.add(dirLight);

  var spriteMap = new THREE.TextureLoader().load("img/sun.png");
  var spriteMaterial = new THREE.SpriteMaterial({ map: spriteMap });
  var sunSprite = new THREE.Sprite(spriteMaterial);
  sunSprite.scale.multiplyScalar(900);
  sunGroup.add(sunSprite);
  sunSprite.position.copy(dirLight.position);


  var moonMap = new THREE.TextureLoader().load("img/moon.png");
  var moonMaterial = new THREE.SpriteMaterial({ map: moonMap });
  var moonSprite = new THREE.Sprite(moonMaterial);
  moonSprite.scale.multiplyScalar(200);
  moonSprite.position.y = -2000;
  moonSprite.position.z = - 250;
  sunGroup.add(moonSprite);

  // var jsonLoader = new THREE.ObjectLoader();
  // jsonLoader.load("models/tree.json", addModelToScene);

  // function addModelToScene(object : THREE.Object3D) {
  //     console.log(object);
  //     scene.add(object);
  //     object.position.set(0,terrain.getHeight(0, 10),10);

  // }

  // const sunSpeed = 18000;
  const sunSpeed = 100000;
  const timeFactor = sunSpeed * Math.PI * 2;
  let time = 0;
  function updateSun() {

    let sinResult = currTime / -sunSpeed;
    sunGroup.rotation.z = sinResult;
    sunGroup.position.x = camera.camera.position.x;
    sunGroup.position.z = camera.camera.position.z;
    sunGroup.position.y = 100;

    time = 24 * (currTime % timeFactor) / timeFactor;
    time += 12;
    if (time > 24) {
      time -= 24;
    }
    gameModel.time = time;

    if (time > 4 && time < 20) {
      dirLight.position.copy(sunSprite.position);
      dirLight.color = sunLightColor;
    } else {
      dirLight.position.copy(moonSprite.position);
      dirLight.color = moonLightColor;
    }

    if (controls.flashLight) {
      light.intensity = 0.6;
    } else {
      light.intensity = 0.0;
    }
    let sunPosition = new THREE.Vector3();
    sunSprite.getWorldPosition(sunPosition);
    let sunlightRatio = 0;
    sunlightRatio = Math.min((sunPosition.y + 200) / 1000, 1);

    terrain.updateTides(sunPosition.y);

    (scene.background as THREE.Color).setRGB(
      sunlightRatio * dayColor.r + nightColor.r,
      sunlightRatio * dayColor.g + nightColor.g,
      sunlightRatio * dayColor.b + nightColor.b)
  }

  var frames = 0;
  var lastUpdate = 0;
  function updateFps() {
    frames++;
    if (frames == 20) {
      // console.log(camera.camera);
      var element = document.getElementById("fps");
      var posX = document.getElementById("posX");
      var posY = document.getElementById("posY");
      var calls = document.getElementById("calls");
      var speed = document.getElementById("speed");
      if (element) {
        var timeDiff = currTime - lastUpdate;
        var timePerFrame = timeDiff / 20;
        var fps = 1000 / timePerFrame;
        element.innerText = Math.round(fps).toString();
        posX.innerText = camera.camera.position.x.toFixed(2);
        posY.innerText = camera.camera.position.z.toFixed(2) + " z:" + camera.camera.position.y.toFixed(2);
        calls.innerText = renderer.info.render.calls.toString();
        speed.innerText = controls.currentVehicle.speed.length().toFixed(2).toString();
        lastUpdate = currTime;
        frames = 0;
      }
    }
  }


  function animate(): void {
    requestAnimationFrame(animate);
    // setTimeout(animate, 1e3 / 30);
    render();
  }

  let currTime = 0, lastTime = 0;

  function render(): void {

    if (gameModel.settings.rendererSettings.settingsChanged) {
      console.log("render settings changed");
      renderer.setPixelRatio(gameModel.settings.rendererSettings.resolution);
      gameModel.settings.rendererSettings.settingsChanged = false;
    }

    currTime = Date.now();
    let timeDiff = Math.min((currTime - lastTime) / 1000, 1);
    lastTime = currTime;
    updateSun();
    updateFps();
    terrain.updateChunks(timeDiff, camera.camera);
    controls.handleMovement(timeDiff);
    renderer.render(scene, camera.camera);
  }

  animate();

  window.onresize = function () {
    camera.camera.aspect = window.innerWidth / window.innerHeight;
    camera.camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };

}
