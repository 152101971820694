import { Controls } from "./controls";
import { getHeight } from "../terrain/utils";
import { isWater } from "../terrain/terrain";

export class Vehicle {
    speed : THREE.Vector2;
    acceleration : number;
    drag : number;
    turnSpeed : number;
    preferredZoom : number;
    camera : THREE.PerspectiveCamera;
    controls : Controls;
    handleMovement(timeDiff : number) : void {

    }
    onInit() : void {

    }
    onDestroy() : void {

    }
}

export class Boat extends Vehicle {
    speed : THREE.Vector2 = new THREE.Vector2();
    constructor(camera : THREE.PerspectiveCamera, controls : Controls) {
       super();
       this.camera = camera;
       this.controls = controls;
    //    var loader = new THREE.GLTFLoader();

    //     loader.load( 'models/untitled.glb', function ( gltf ) {
    //         console.log(gltf);
    //         // for (let i = 0; i < 1; i++) {
    //             let geometry = gltf.scene.children[0].geometry;
    //             console.log(geometry.toJSON());
    //             let mesh = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial());
    //             mesh.position.z = -20;
    //             // gltf.scene.position.z = -10;
    //             camera.add(mesh);
    //         // }
    //     }, undefined, function ( error ) {

    //         console.error( error );

    //     } );
    }
}

export class Walking extends Vehicle {
    speed : THREE.Vector2 = new THREE.Vector2();
    acceleration: number = 50;
    drag: number = -6.5;
    turnSpeed: number = 2.5;
    preferredZoom: number = 1.8;
    constructor(camera : THREE.PerspectiveCamera, controls : Controls) {
        super();
        this.camera = camera;
        this.controls = controls;
    }
    handleMovement(timeDiff: number): void {

        if (this.camera.rotation.x <= 0) {
            this.camera.rotation.x += 2.0 * timeDiff;
            if (this.camera.rotation.x > 0)
                this.camera.rotation.x = 0;
        }

        var moveVec = new THREE.Vector2(0, 0);
        var height = getHeight(this.camera.position.x, this.camera.position.z);

        if (this.controls.KeysPressed.w) {
            moveVec.y += 1;
        }
        if (this.controls.KeysPressed.s) {
            moveVec.y -= 1;
        }
        if (this.controls.KeysPressed.q) {
            moveVec.x -= 1;
        }
        if (this.controls.KeysPressed.e) {
            moveVec.x += 1;
        }
        if (this.controls.KeysPressed.a) {
            this.camera.rotation.y += this.turnSpeed * timeDiff;
        }
        if (this.controls.KeysPressed.d) {
            this.camera.rotation.y -= this.turnSpeed * timeDiff;
        }
        moveVec.rotateAround(new THREE.Vector2(), this.camera.rotation.y);
        
        let dragModifier = 1;
        if (isWater(height)) {
            dragModifier = 3;
        } else {
            // let destHeight = getHeight(this.camera.position.x + (moveVec.x * 2), this.camera.position.z + (moveVec.y * 2));
            // let heightDiff = Math.pow(Math.abs(destHeight - height) + 0.5, 2);
            // dragModifier = Math.min(Math.max(1, heightDiff), 3);
        }
        // apply acceleration
        if (moveVec.length() > 0) {
            moveVec.normalize();
            moveVec.multiplyScalar(this.acceleration * timeDiff);
        }
        // apply drag
        let currSpeed = this.speed.length();
        if (currSpeed > 0) {
            let drag =  new THREE.Vector2();
            drag.set(this.speed.x, this.speed.y);
            drag.multiplyScalar(this.drag * timeDiff * dragModifier);
            moveVec.add(drag);
        }
        this.speed.add(moveVec);
        this.camera.position.x += this.speed.x * timeDiff;
        this.camera.position.z -= this.speed.y * timeDiff;

        height = getHeight(this.camera.position.x, this.camera.position.z);
        if (isWater(height)) {
            height = Math.max(height, 3.5);
        }
        if (this.camera.position.y < height + 0.5)
            this.camera.position.y = height + 0.5;
        
        var diff = (height + this.preferredZoom) - this.camera.position.y;
        if (diff > 0) {
            this.camera.position.y += diff * timeDiff * 5;
        } else {
            this.camera.position.y += diff * timeDiff * 5;
        }
    }
}

export class MapMode extends Vehicle {
    speed : THREE.Vector2 = new THREE.Vector2();
    preferredZoom: number = 80;
    ambientLight : THREE.AmbientLight;
    constructor(camera : THREE.PerspectiveCamera, controls : Controls) {
        super();
        this.camera = camera;
        this.controls = controls;
        this.ambientLight = new THREE.AmbientLight(0x606060, 0.5);
    }
    onInit() : void {
        this.camera.add(this.ambientLight);
        // this.camera.rotation.z = this.camera.rotation.y;
        this.camera.rotation.y = 0;
    }
    onDestroy() : void {
        this.camera.remove(this.ambientLight);
        // this.camera.rotation.y = this.camera.rotation.z;
        // this.camera.rotation.z = 0;
    }
    handleMovement(timeDiff : number) {
        if (this.camera.rotation.x >= -1.57) {
            this.camera.rotation.x -= 8.0 * timeDiff;
            if (this.camera.rotation.x < -1.57)
                this.camera.rotation.x = -1.57;
        }
        
        var height = getHeight(this.camera.position.x, this.camera.position.z);
        var diff = (height + this.preferredZoom) - this.camera.position.y;
        if (diff > 0) {
            this.camera.position.y += diff * timeDiff * 5;
        } else {
            this.camera.position.y += diff * timeDiff * 5;
        }
        return;
    }
}